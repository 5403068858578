import React from 'react';
import IconButton from 'material-ui/IconButton';
import SidebarClose from 'material-ui/svg-icons/navigation/close';
import SidebarOpen from 'material-ui/svg-icons/navigation/menu';
import { BrowserRouter as Router } from 'react-router-dom';
import AppBar from 'material-ui/AppBar';
import Sidebar from './Sidebar';
import AppRouter from '../../../Router';

class Layout extends React.Component {
  state = {
    open: true,
  };

  toggleSidebar = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div style={inlineStyles.container}>
        <React.Fragment>
          <AppBar
            title="Mirage ID Admin"
            iconElementLeft={
              <IconButton onClick={this.toggleSidebar}>
                {this.state.open ? <SidebarClose /> : <SidebarOpen />}
              </IconButton>
            }
          />
          <Router>
            <div style={inlineStyles.body}>
              <Sidebar open={this.state.open} />
              <div style={inlineStyles.component}>
                <AppRouter />
              </div>
            </div>
          </Router>
        </React.Fragment>
      </div>
    );
  }
}

const inlineStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    fontFamily: 'sans-serif',
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  component: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
  },
};

export default Layout;
