import {
  LOAD_USER_ADMIN,
  EDIT_USER_ADMIN,
  USER_ADMIN_RECEIVED,
  UNMOUNT_USER_ADMIN,
} from '..';

export const loadUserAdmin = (uid) => ({
  type: LOAD_USER_ADMIN,
  payload: {
    uid,
  },
});

export const editUserAdmin = (uid, fields) => ({
  type: EDIT_USER_ADMIN,
  payload: {
    uid,
    fields,
  },
});

export const userAdminReceived = (data) => ({
  type: USER_ADMIN_RECEIVED,
  payload: {
    data,
  },
});

export const unmountUserAdmin = (uid) => ({
  type: UNMOUNT_USER_ADMIN,
  payload: {
    uid,
  },
});
