import React from 'react';

const Column = ({ children, style }) => (
  <div style={{ ...styles.column, ...style }}>{children}</div>
);

const styles = {
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default Column;
