import React from 'react';
import { connect } from 'react-redux';
import Table from './Table';
import DebouncedTextField from '../DebouncedTextField';
import { updateFormField, clearFormField } from '../../actions/Form/index';

const fieldId = 'searchTerm';

class SearchableTable extends React.Component {
  render() {
    const { searchTerm, data, ...props } = this.props;

    const tableData = Array.isArray(data)
      ? data.filter((entry) =>
          searchTerm && searchTerm.length > 0
            ? searchObjectValues(entry, props.headers, searchTerm.trim())
            : true
        )
      : [];

    return (
      <React.Fragment>
        <DebouncedTextField id={fieldId} label="Search" />
        <Table {...props} data={tableData} />
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this.props.clearFormField(fieldId);
  }
}

const searchObjectValues = (searchable, columns, term) => {
  const modifiers = columns.reduce((acc, { modifier, id }) => {
    if (typeof modifier === 'function') {
      acc[id] = modifier;
    }
    return acc;
  }, {});
  return Object.entries(searchable).some(([key, value]) => {
    if (!value) return false;
    const stringOrNumberMatch =
      (typeof value === 'string' || typeof value === 'number') &&
      value.toString().toLowerCase().includes(term.toLowerCase());

    const modifierMatch =
      typeof modifiers[key] === 'function'
        ? modifiers[key](value, searchable)
            .toString()
            .toLowerCase()
            .includes(term.toLowerCase())
        : false;
    return stringOrNumberMatch || modifierMatch;
  });
};

const mapStateToProps = (state) => ({
  searchTerm: state.formFields[fieldId],
});

export default connect(mapStateToProps, { updateFormField, clearFormField })(
  SearchableTable
);
