import firebase from '../../lib/firebaseHelpers';
import { LOAD_USER } from '../../actions';
import { userReceived } from '../../actions/Users';

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === LOAD_USER) {
      const { db } = firebase;
      const usersRef = db.ref(`users/${action.payload.uid}`);

      usersRef.on('value', (snapshot) => {
        const data = snapshot.val();

        dispatch(userReceived(data));
      });
    }
  };
