import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import {
  STRING_CANCEL,
  STRING_OK,
  TITLE_MODAL_CONFIRM,
  TITLE_MODAL_MESSAGE,
} from '../../constants';

/**
 * A modal dialog can only be closed by selecting one of the actions.
 */

class ConfirmationModel extends React.Component {
  state = {
    open: false,
    enteredTerm: '',
  };

  render() {
    const {
      isLoading,
      message,
      onClose = undefined,
      onClick,
      label,
      confirmationTerm,
      disabled,
    } = this.props;
    const { open, enteredTerm } = this.state;

    const setOpen = (value) => {
      this.setState({ open: value });
    };

    const setTerm = (value) => {
      this.setState({ enteredTerm: value });
    };

    const handleClose = () => {
      this.setState({ open: false, enteredTerm: '' });
    };

    const actions = [
      <FlatButton
        key={[0]}
        primary={true}
        disabled={isLoading}
        onClick={() => {
          setOpen(false);
          setTerm('');
          if (onClose) {
            onClose();
          }
        }}
      >
        <div style={useStyles.wrapperButton}>
          <span style={useStyles.spanButton}>
            {message ? STRING_OK : STRING_CANCEL}
          </span>
        </div>
      </FlatButton>,
      !message && (
        <FlatButton
          key={[1]}
          secondary={true}
          disabled={isLoading || enteredTerm !== confirmationTerm}
          onClick={(e) => {
            onClick(e, handleClose);
          }}
        >
          <div style={useStyles.wrapperButton}>
            <span style={useStyles.spanButton}>{label}</span>
            {isLoading && <CircularProgress color="secondary" size={20} />}
          </div>
        </FlatButton>
      ),
    ];

    return (
      <div>
        <RaisedButton
          label={label}
          onClick={() => setOpen(true)}
          disabled={disabled}
          secondary
        />
        <Dialog
          title={message ? TITLE_MODAL_MESSAGE : TITLE_MODAL_CONFIRM}
          actions={actions}
          open={open}
        >
          {message ? (
            <span>{message}</span>
          ) : (
            <>
              <div>
                Please enter <b>{confirmationTerm}</b> below to confirm:
              </div>
              <TextField
                value={enteredTerm}
                onChange={(event) => setTerm(event.target.value)}
                floatingLabelText="Enter the phrase to confirm"
              />
            </>
          )}
        </Dialog>
      </div>
    );
  }
}

const useStyles = {
  wrapperButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '0px 16px',
  },
  spanButton: {
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
};

export default ConfirmationModel;
