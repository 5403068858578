import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import Config from '../../config';

function init() {
  firebase.initializeApp(Config.firebase);
  const db = firebase.database();

  return {
    db,
    auth: firebase.auth,
  };
}

export default init();
