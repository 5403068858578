import {
  LOAD_IDENTITY,
  IDENTITY_RECEIVED,
  UNMOUNT_IDENTITY,
} from '../../actions';

const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_IDENTITY:
      return true;
    case IDENTITY_RECEIVED:
      return initialState;
    case UNMOUNT_IDENTITY:
      return initialState;
    default:
      return state;
  }
};
