import {
  LOAD_USER_ADMIN,
  USER_ADMIN_RECEIVED,
  UNMOUNT_USER_ADMIN,
} from '../../actions';

const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_ADMIN:
      return true;
    case USER_ADMIN_RECEIVED:
      return initialState;
    case UNMOUNT_USER_ADMIN:
      return initialState;
    default:
      return state;
  }
};
