import firebase from '../../lib/firebaseHelpers';
import { UNMOUNT_USER_IDENTITIES } from '../../actions';

export default () => (next) => (action) => {
  next(action);

  if (action.type === UNMOUNT_USER_IDENTITIES) {
    const { db } = firebase;

    db.ref(`identities`).off();
  }
};
