import {
  UPDATE_FORM_FIELD,
  CLEAR_FORM_FIELD,
  SUBMIT_FORM,
  UNMOUNT_FORM,
  FORM_ERROR,
} from '..';

export const updateFormField = (id, value) => ({
  type: UPDATE_FORM_FIELD,
  payload: {
    id,
    value,
  },
});

export const clearFormField = (id) => ({
  type: CLEAR_FORM_FIELD,
  payload: {
    id,
  },
});

export const submitForm = () => ({
  type: SUBMIT_FORM,
});

export const unmountForm = () => ({
  type: UNMOUNT_FORM,
});

export const formError = (error) => ({
  type: FORM_ERROR,
  payload: {
    error,
  },
});
