export default {
  firebase: {
    apiKey: 'AIzaSyAMAftpeoAJ6p_V95Yoek2jpUeIp1OhU70',
    authDomain: 'mirage-15494.firebaseapp.com',
    databaseURL: 'https://mirage-15494.firebaseio.com/',
    projectId: 'mirage-15494',
    storageBucket: 'mirage-15494.appspot.com',
    // messagingSenderId: "1039217910993"
  },
  apiUrl: 'https://api.mirageid.com/api',
  baseUrl: 'https://api.mirageid.com',
  chargebeeUrl: 'https://mirageid.chargebee.com/d/invoices/',
};
