import React from 'react';
import { connect } from 'react-redux';
import { user as userAPI } from 'mirage-api-client';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import Column from '../../ui/Column';
import Row from '../../ui/Row';
import ConfirmationButton from '../../ui/ConfirmationButton';

function AdminPrivilege({ user = {}, auth = {} }) {
  const { uid: authUID } = auth;
  const { uid } = user;
  const isAdmin = user && user.customClaims && user.customClaims.admin;
  const [inProgress, setInProgress] = React.useState(false);
  const { newSnackBar } = useSnackBar();

  async function promote(event, closeDialog) {
    try {
      setInProgress(true);
      await userAPI.promote(uid, 'admin');
      closeDialog();
    } catch (e) {
      newSnackBar({
        message: 'Failed to promote the user.',
        type: 'error',
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } finally {
      setInProgress(false);
    }
  }

  async function demote(event, closeDialog) {
    try {
      setInProgress(true);
      await userAPI.demote(uid, 'admin');
      closeDialog();
    } catch (e) {
      newSnackBar({
        message: 'Failed to demote the user.',
        type: 'error',
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } finally {
      setInProgress(false);
    }
  }

  return (
    <Column style={{ margin: 16, maxWidth: 600, padding: 4 }}>
      {isAdmin ? 'user is a system admin' : 'user is not a system admin'}
      {authUID !== uid && (
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 4,
            flexDirection: 'row',
          }}
        >
          <ConfirmationButton
            confirmationTerm="promote"
            label="Make an admin"
            onClick={promote}
            disabled={inProgress}
          />
          <ConfirmationButton
            confirmationTerm="demote"
            label="Remove admin privileges"
            onClick={demote}
            disabled={inProgress}
          />
        </Row>
      )}
    </Column>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.data,
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminPrivilege);
