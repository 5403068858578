import firebase from '../../lib/firebaseHelpers';
import { EDIT_USER_ADMIN } from '../../actions';

export default () => (next) => (action) => {
  next(action);

  if (action.type === EDIT_USER_ADMIN) {
    const { uid, fields } = action.payload;

    firebase.db.ref(`users_admin/${uid}`).update(fields);
  }
};
