import React from 'react';
import { Provider } from 'react-redux';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Theme from 'mirage-components/dist/Theme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { init } from 'mirage-api-client';
import { SnackBarProvider } from 'mirage-components/dist/SnackBar';
import reduxStore from './store';
import Layout from './Components/ui/Layout';
import Auth from './Components/Auth';
import Config from './config';

const { baseUrl } = Config;

function App() {
  React.useEffect(() => {
    init({ baseUrl });
  }, []);

  return (
    <MuiThemeProvider muiTheme={getMuiTheme()}>
      <Theme>
        <Provider store={reduxStore}>
          <Auth>
            <SnackBarProvider>
              <Layout />
            </SnackBarProvider>
          </Auth>
        </Provider>
      </Theme>
    </MuiThemeProvider>
  );
}

export default App;
