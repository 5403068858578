import React from 'react';
import Paper from 'material-ui/Paper';
import MenuItem from 'material-ui/MenuItem';
import { NavLink } from 'react-router-dom';
import routes from '../../../routes';
import firebase from '../../../lib/firebaseHelpers';

const Sidebar = ({ open }) =>
  open && (
    <Paper zDepth={2} style={inlineStyles.sidebar}>
      {routes.map(
        ({ label, path }) =>
          label && (
            <NavLink to={path} key={path}>
              <MenuItem>{label}</MenuItem>
            </NavLink>
          )
      )}
      <MenuItem onClick={() => firebase.auth().signOut()}>Sign Out</MenuItem>
    </Paper>
  );

const inlineStyles = {
  sidebar: {
    width: 200,
    marginRight: 5,
    zIndex: 1000,
  },
};

export default Sidebar;
