import firebase from '../../lib/firebaseHelpers';
import { LOAD_USER_IDENTITIES } from '../../actions';
import { userIdentitiesReceived } from '../../actions/Identities';

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === LOAD_USER_IDENTITIES) {
      const { db } = firebase;
      const identitiesRef = db
        .ref('identities')
        .orderByChild('userId')
        .equalTo(action.payload.uid);

      identitiesRef.on('value', (snapshot) => {
        const data = snapshot.val();

        dispatch(userIdentitiesReceived(data));
      });
    }
  };
