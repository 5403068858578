import { UPDATE_FORM_FIELD, CLEAR_FORM_FIELD, UNMOUNT_FORM } from '../actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.payload.id]: action.payload.value,
      };
    case CLEAR_FORM_FIELD: {
      // eslint-disable-next-line no-unused-vars
      const { [action.payload.id]: toDelete, ...rest } = state;
      return rest;
    }
    case UNMOUNT_FORM:
      return initialState;
    default:
      return state;
  }
};
