import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Table as MaterialTable,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { IconButton } from 'material-ui';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Row from './Row';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';

function opposite(direction) {
  return direction === DESCENDING ? ASCENDING : DESCENDING;
}

function sortData(sort, data) {
  if (!sort) return data;

  const [id, direction] = sort.split('-');
  if (direction === ASCENDING) {
    return data.sort((a, b) => {
      if (!a[id]) return 1;
      if (!b[id]) return -1;
      if (a[id] > b[id]) return -1;
      if (a[id] < b[id]) return 1;
      return 0;
    });
  }
  return data.sort((a, b) => {
    if (!a[id]) return 1;
    if (!b[id]) return -1;
    if (a[id] < b[id]) return -1;
    if (a[id] > b[id]) return 1;
    return 0;
  });
}

function SortButton({ id, direction, onChange }) {
  function handleClick() {
    onChange(id, opposite(direction));
  }

  const directionIcon =
    direction === DESCENDING ? (
      <KeyboardArrowDownIcon />
    ) : (
      <KeyboardArrowUpIcon />
    );

  const placeholder = <KeyboardArrowDownIcon color="disabled" />;

  return (
    <IconButton onClick={handleClick}>
      {direction ? directionIcon : placeholder}
    </IconButton>
  );
}

function Table({ headers, data, target, history }) {
  const [sort, setSort] = React.useState(null);
  const [sortId, sortDirection] = !sort ? [null, null] : sort.split('-');

  function handleSortChange(id, direction) {
    setSort(`${id}-${direction}`);
  }

  const sortedData = sortData(sort, data);

  const handleCellClick = (row, _column, event) => {
    if (target) {
      const url = `${target}/${data[row].id}`;
      if (event.metaKey || event.ctrlKey) {
        window.open(url, '_blank');
      } else {
        history.push(url);
      }
    }
  };

  return (
    <MaterialTable onCellClick={handleCellClick}>
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          {headers.map(({ id, label }) => (
            <TableHeaderColumn key={id}>
              <Row style={{ alignItems: 'center' }}>
                <h4>{label}</h4>
                <SortButton
                  id={id}
                  direction={sortId === id ? sortDirection : null}
                  onChange={handleSortChange}
                />
              </Row>
            </TableHeaderColumn>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {sortedData.map((item) => (
          <TableRow key={item.id} style={{ cursor: 'pointer' }}>
            {headers.map(({ id, modifier }) => (
              <TableRowColumn key={id}>
                {typeof modifier === 'function'
                  ? modifier(item[id], item)
                  : item[id]}
              </TableRowColumn>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MaterialTable>
  );
}

export default withRouter(Table);
