import React from 'react';

const FieldContainer = ({ children, style = {} }) => (
  <div style={{ ...styles.container, ...style }}>{children}</div>
);

const styles = {
  container: {
    padding: 40,
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: 'white',
    fontFamily: 'sans-serif',
  },
};

export default FieldContainer;
