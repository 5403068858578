import { IDENTITY_RECEIVED, UNMOUNT_IDENTITY } from '../../actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case IDENTITY_RECEIVED:
      return {
        ...state,
        [action.payload.data.id]: action.payload.data,
      };
    case UNMOUNT_IDENTITY:
      // eslint-disable-next-line no-case-declarations
      const { [action.payload.id]: _, ...rest } = state;
      return rest;
    default:
      return state;
  }
};
