import firebase from '../../lib/firebaseHelpers';
import { LOAD_USERS } from '../../actions';
import { usersReceived } from '../../actions/Users';

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === LOAD_USERS) {
      try {
        const { db } = firebase;
        const usersRef = db.ref('users');

        usersRef.on('value', (snapshot) => {
          const data = snapshot.val();

          dispatch(usersReceived(data));
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
