import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducers';
import middlewares from './middleware';

const create = (initialState) => {
  const reduxMiddlewares = [thunk, ...middlewares];

  return createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...reduxMiddlewares))
  );
};

export default create({});
