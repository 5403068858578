import React from 'react';
import { connect } from 'react-redux';
import BillingView, { KeyValuePair } from 'mirage-components/dist/billing/View';
import { billing } from 'mirage-api-client';
import {
  makeStyles,
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter, Link, useLocation } from 'react-router-dom';
import { useSnackBar } from 'mirage-components/dist/SnackBar';
import { loadUser, unmountUser } from '../../actions/Users';
import { loadUserAdmin, unmountUserAdmin } from '../../actions/Users/admin';
import Config from '../../config';

const useStyles = makeStyles({
  buttonRow: {
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  backdrop: {
    flex: 1,
    zIndex: 1,
  },
  noIdentities: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 80,
  },
  fab: {
    position: 'fixed',
    right: 16,
    bottom: 16,
    zIndex: 100,
  },
  userNotes: {
    whiteSpace: 'pre-line',
  },
  redEmoji: {
    fontSize: 20,
    fontWeight: 400,
  },
});

function View({
  user,
  userAdmin,
  loadUser: load,
  unmountUser: unmount,
  loadUserAdmin: loadAdmin,
  unmountUserAdmin: unmountAdmin,
  match: { params: { uid } = {} } = {},
  history,
}) {
  const classes = useStyles();
  const [bill, setBill] = React.useState(false);
  const [periods, setPeriods] = React.useState(false);
  const [invoiceInProgress, setInvoiceInProgress] = React.useState(false);
  const location = useLocation();
  const notes = userAdmin && userAdmin.data && userAdmin.data.notes;

  React.useEffect(() => {
    load(uid);
    loadAdmin(uid);
    return () => {
      unmount(uid);
      unmountAdmin(uid);
    };
  }, [uid, load, unmount, loadAdmin, unmountAdmin]);

  const period = React.useMemo(() => {
    if (!location.search) return false;
    return new URLSearchParams(location.search).get('period');
  }, [location]);

  React.useEffect(() => {
    billing.periods(uid).then(setPeriods);
  }, [uid]);

  React.useEffect(() => {
    if (period) {
      billing.get(uid, period).then(setBill);
    } else {
      billing.get(uid).then(setBill);
    }
  }, [uid, period]);

  const onChangePeriod = (periodId) => {
    history.push(`/billing/${uid}?period=${periodId}`);
  };

  const handleEditAdjustedTotal = async (value) =>
    billing.setAdjustedTotal(uid, period, value).then(setBill);

  const handleDeleteInvoiceClick = () => {
    setInvoiceInProgress(true);
    billing
      .deletePeriodInvoice(uid, period)
      .then(setBill)
      .finally(() => setInvoiceInProgress(false));
  };

  const title = (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <IconButton
          aria-label="back"
          onClick={(_e) => history.push('/billing/')}
        >
          <ArrowBackIcon />
        </IconButton>
      </Grid>
      <Grid item>Identity Usage</Grid>
    </Grid>
  );

  function CreateInvoiceButton() {
    const { newSnackBar } = useSnackBar();
    const handleCreateInvoiceClick = () => {
      setInvoiceInProgress(true);
      billing
        .createPeriodInvoice(uid, period)
        .then(setBill)
        .catch(() => {
          newSnackBar({
            message: 'Failed to create the invoice.',
            type: 'error',
            position: {
              vertical: 'top',
              horizontal: 'center',
            },
          });
        })
        .finally(() => {
          setInvoiceInProgress(false);
        });
    };
    return (
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={handleCreateInvoiceClick}
        disabled={invoiceInProgress}
      >
        Create Invoice
      </Button>
    );
  }

  React.useEffect(() => {
    document.title = 'Billing Detail | Mirage ID Admin';
  }, []);

  if (bill && periods) {
    return (
      <Container maxWidth="xl">
        <BillingView
          bill={bill}
          periods={periods}
          onChangePeriod={onChangePeriod}
          onEditAdjustedTotal={handleEditAdjustedTotal}
          title={title}
          extraUserDetails={
            <>
              <KeyValuePair
                label="UserID"
                value={
                  <Link to={`/users/${bill.user.uid}`}>{bill.user.uid}</Link>
                }
              />

              <KeyValuePair label="Plan" value={user.accountType} />

              <KeyValuePair
                label="Invoice ID"
                value={
                  bill && bill.billing_rate && bill.billing_rate.invoice_id ? (
                    <a
                      className="ignore-click"
                      href={`${Config.chargebeeUrl}${bill.billing_rate.invoice_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {bill.billing_rate.invoice_id}
                    </a>
                  ) : (
                    <span className={classes.redEmoji}>-</span>
                  )
                }
              />

              {user.status !== 'trial' && (
                <div style={{ marginBottom: 6 }}>
                  {bill && bill.billing_rate && bill.billing_rate.invoice_id ? (
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={handleDeleteInvoiceClick}
                      disabled={invoiceInProgress}
                    >
                      Delete Invoice
                    </Button>
                  ) : (
                    <CreateInvoiceButton />
                  )}
                </div>
              )}

              <KeyValuePair
                style={{ alignItems: 'flex-start' }}
                styleLabel={{ minWidth: 92 }}
                styleValue={{ whiteSpace: 'pre-line' }}
                label="User Notes"
                value={notes}
              />
            </>
          }
        />
      </Container>
    );
  }

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.data,
  userAdmin: state.userAdmin,
});

export default withRouter(
  connect(mapStateToProps, {
    loadUser,
    unmountUser,
    loadUserAdmin,
    unmountUserAdmin,
  })(View)
);
