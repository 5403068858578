import React from 'react';
import { connect } from 'react-redux';
import firebase from '../../lib/firebaseHelpers';
import { authStateChanged, login } from '../../actions/Auth';
import { unmountForm } from '../../actions/Form';
import LoginForm from './LoginForm';

const { auth } = firebase;

class Auth extends React.Component {
  componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          if (claims.admin) {
            this.props.authStateChanged(user);
          } else {
            auth().signOut();
          }
        });
      } else {
        this.props.authStateChanged(user);
      }
    });
  }

  render() {
    return this.props.user && this.props.user.uid ? (
      this.props.children
    ) : (
      <LoginForm />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default connect(mapStateToProps, {
  authStateChanged,
  login,
  unmountForm,
})(Auth);
