import { combineReducers } from 'redux';
import formFields from './formFields';
import formErrors from './formErrors';
import users from './users';
import user from './user';
import userAdmin from './userAdmin';
import userIdentities from './userIdentities';
import identity from './identity';
import auth from './auth';

export default combineReducers({
  formFields,
  formErrors,
  users,
  user,
  userAdmin,
  userIdentities,
  identity,
  auth,
});
