export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const CLEAR_FORM_FIELD = 'CLEAR_FORM_FIELD';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const FORM_ERROR = 'FORM_ERROR';
export const UNMOUNT_FORM = 'UNMOUNT_FORM';

export const LOAD_USERS = 'LOAD_USERS';
export const USERS_RECEIVED = 'USERS_RECEIVED';
export const UNMOUNT_USERS = 'UNMOUNT_USERS';
export const LOAD_USER = 'LOAD_USER';
export const USER_RECEIVED = 'USER_RECEIVED';
export const UNMOUNT_USER = 'UNMOUNT_USER';
export const EDIT_USER = 'EDIT_USER';

export const LOAD_USER_ADMIN = 'LOAD_USER_ADMIN';
export const EDIT_USER_ADMIN = 'EDIT_USER_ADMIN';
export const USER_ADMIN_RECEIVED = 'USER_ADMIN_RECEIVED';
export const UNMOUNT_USER_ADMIN = 'UNMOUNT_USER_ADMIN';

export const LOAD_USER_IDENTITIES = 'LOAD_USER_IDENTITIES';
export const USER_IDENTITIES_RECEIVED = 'USER_IDENTITIES_RECEIVED';
export const UNMOUNT_USER_IDENTITIES = 'UNMOUNT_USER_IDENTITIES';
export const LOAD_IDENTITY = 'LOAD_IDENTITY';
export const IDENTITY_RECEIVED = 'IDENTITY_RECEIVED';
export const UNMOUNT_IDENTITY = 'UNMOUNT_IDENTITY';
export const REFRESH_IDENTITY = 'REFRESH_IDENTITY';
export const REFRESH_IDENTITY_SUCCESS = 'REFRESH_IDENTITY_SUCCESS';
export const REFRESH_IDENTITY_FAILURE = 'REFRESH_IDENTITY_FAILURE';

export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';
export const LOGIN = 'LOGIN';
