import React from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import Checkbox from 'material-ui/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { user as userAPI } from 'mirage-api-client';
import { loadUsers, unmountUsers } from '../../actions/Users';
import LoadingComponent from '../ui/LoadingComponent';
import UsersTable from './UsersTable';
import FieldContainer from '../ui/FieldContainer';

function formatDate(value) {
  if (value) {
    const date = format(new Date(value), 'yyyy-MM-dd');
    const time = new Intl.DateTimeFormat('en', {
      timeStyle: 'short',
    }).format(new Date(value));
    return `${date} ${time}`;
  }
  return undefined;
}

function Users({
  loadUsers: load,
  unmountForm: unmount,
  users: { inProgress, data },
}) {
  const [showDeleted, setShowDeleted] = React.useState(false);
  const [showChildren, setShowChildren] = React.useState(false);

  function toggleDeleted() {
    setShowDeleted((value) => !value);
  }

  function toggleChildren() {
    setShowChildren((value) => !value);
  }

  React.useEffect(() => {
    document.title = 'Users | Mirage ID Admin';
  }, []);

  React.useEffect(() => {
    load();
    return unmount;
  }, [load, unmount]);

  const updateBillingType = async (event, uid) => {
    event.stopPropagation();
    const fields = {
      billingType: event.target.value,
    };
    await userAPI.update(uid, fields);
  };

  const headers = React.useMemo(
    () => [
      {
        id: 'email',
        label: 'Email',
      },
      {
        id: 'accountType',
        label: 'Plan',
      },
      {
        id: 'status',
        label: 'Status',
      },
      {
        id: 'billingType',
        label: 'Billing Type',
        modifier: function billingTypeModifier(value, user) {
          return (
            !user.parentUID && (
              <Select
                name={user.id}
                value={value || 'standard'}
                onChange={(e) => updateBillingType(e, user.id)}
              >
                <MenuItem value="standard">standard</MenuItem>
                <MenuItem value="enterprise">enterprise</MenuItem>
              </Select>
            )
          );
        },
      },
      ...(showChildren
        ? [
            {
              id: 'parentUID',
              label: 'Parent user',
              modifier: (value) => (data[value] ? data[value].email : value),
            },
          ]
        : []),
      {
        id: 'createdAt',
        label: 'Created At',
        modifier: (value) => formatDate(value),
      },
    ],
    [data, showChildren]
  );

  const users = React.useMemo(
    () =>
      Object.entries(data)
        .map(([key, value]) => ({ id: key, ...value }))
        .filter((user) => {
          if (!showChildren && user.parentUID) return false;
          if (!showDeleted && user.status === 'deleted') return false;
          return true;
        }),
    [data, showChildren, showDeleted]
  );

  return (
    <FieldContainer>
      {inProgress ? (
        <LoadingComponent />
      ) : (
        <>
          <h3>Users</h3>
          <Checkbox
            label="Show deleted users"
            checked={showDeleted}
            onCheck={toggleDeleted}
          />
          <Checkbox
            label="Show child users"
            checked={showChildren}
            onCheck={toggleChildren}
          />
          <UsersTable users={users} headers={headers} />
        </>
      )}
    </FieldContainer>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {
  loadUsers,
  unmountUsers,
})(Users);
