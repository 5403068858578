import firebase from '../../lib/firebaseHelpers';
import { UNMOUNT_USER } from '../../actions';

export default () => (next) => (action) => {
  next(action);

  if (action.type === UNMOUNT_USER && action.payload.uid) {
    const { db } = firebase;

    db.ref(`users/${action.payload.uid}`).off();
  }
};
