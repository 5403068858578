import React from 'react';
import { connect } from 'react-redux';
import Wysiwyg from '../../Wysiwyg';
import {
  loadUserAdmin,
  editUserAdmin,
  unmountUserAdmin,
} from '../../../actions/Users/admin';

class Notes extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.loadUserAdmin(this.props.uid);
  }

  onChange = (field, value) =>
    this.props.editUserAdmin(this.props.uid, { [field]: value });

  render() {
    return (
      <React.Fragment>
        <h3>Notes</h3>
        <Wysiwyg
          id="notes"
          value={this.props.userAdmin.data && this.props.userAdmin.data.notes}
          onChange={this.onChange}
        />
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    this.props.unmountUserAdmin(this.props.uid);
  }
}

const mapStateToProps = (state) => ({
  userAdmin: state.userAdmin,
});

export default connect(mapStateToProps, {
  loadUserAdmin,
  editUserAdmin,
  unmountUserAdmin,
})(Notes);
