import { FORM_ERROR, UNMOUNT_FORM } from '../actions';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case FORM_ERROR:
      return action.payload.error;
    case UNMOUNT_FORM:
      return initialState;
    default:
      return state;
  }
};
