import { LOAD_USERS, USERS_RECEIVED, UNMOUNT_USERS } from '../../actions';

const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return true;
    case USERS_RECEIVED:
      return initialState;
    case UNMOUNT_USERS:
      return initialState;
    default:
      return state;
  }
};
