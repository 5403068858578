import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from '../TextField';
import { unmountForm } from '../../actions/Form';
import { login } from '../../actions/Auth';

class Form extends Component {
  onSubmit = (event) => {
    event.preventDefault();

    this.props.login();
  };

  render() {
    return (
      <Paper zDepth={1} style={styles.container}>
        Sign In
        <form onSubmit={this.onSubmit} style={styles.form}>
          <TextField id="email" label="Email" />
          <TextField id="password" label="Password" type="password" />
          <RaisedButton
            type="sumbit"
            label="Submit"
            style={styles.submit}
            primary
            fullWidth
          />
          {this.props.error && (
            <div style={styles.error}>{this.props.error}</div>
          )}
        </form>
      </Paper>
    );
  }

  componentWillUnmount() {
    this.props.unmountForm();
  }
}

const styles = {
  container: {
    maxWidth: 400,
    minWidth: 350,
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 40,
    textAlign: 'center',
    paddingTop: 20,
    fontSize: 20,
  },
  form: {
    padding: 40,
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  submit: {
    marginTop: 20,
  },
  error: {
    color: 'red',
    marginTop: 10,
    fontSize: 14,
  },
};

const mapStateToProps = (state) => ({
  fields: state.formFields,
  error: state.formErrors,
});

export default connect(mapStateToProps, {
  login,
  unmountForm,
})(Form);
