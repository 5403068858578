import { AUTH_STATE_CHANGED } from '../../actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_STATE_CHANGED:
      return action.payload.user;
    default:
      return state;
  }
};
