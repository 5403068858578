import { AUTH_STATE_CHANGED, LOGIN } from '..';

export const login = () => ({
  type: LOGIN,
});

export const authStateChanged = (user) => ({
  type: AUTH_STATE_CHANGED,
  payload: {
    user,
  },
});
