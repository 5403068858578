import React from 'react';
import { connect } from 'react-redux';
import { loadUsers, unmountUsers } from '../../actions/Users';
import LoadingComponent from '../ui/LoadingComponent';
import UsersTable from '../Users/UsersTable';
import FieldContainer from '../ui/FieldContainer';

function AdminList({
  loadUsers: load,
  unmountForm: unmount,
  users: { inProgress, data },
}) {
  React.useEffect(() => {
    load();
    return unmount;
  }, [load, unmount]);

  const headers = React.useMemo(
    () => [
      {
        id: 'email',
        label: 'Email',
      },
      {
        id: 'uid',
        label: 'UID',
      },
    ],
    []
  );

  const users = React.useMemo(
    () => Object.entries(data).map(([key, value]) => ({ id: key, ...value })),
    [data]
  );

  const activeUsers = React.useMemo(
    () => users.filter((user) => !user.deleted),
    [users]
  );

  const adminUsers = React.useMemo(
    () =>
      activeUsers.filter(
        (user) => user.customClaims && user.customClaims.admin
      ),
    [activeUsers]
  );

  React.useEffect(() => {
    document.title = 'Admin List | Mirage ID Admin';
  }, []);

  return (
    <FieldContainer>
      {inProgress ? (
        <LoadingComponent />
      ) : (
        <>
          <h3>Admin Users</h3>
          <UsersTable users={adminUsers} headers={headers} />
        </>
      )}
    </FieldContainer>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {
  loadUsers,
  unmountUsers,
})(AdminList);
