import firebase from '../../lib/firebaseHelpers';
import { LOAD_USER_ADMIN } from '../../actions';
import { userAdminReceived } from '../../actions/Users/admin';

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === LOAD_USER_ADMIN) {
      const { db } = firebase;
      const usersRef = db.ref(`users_admin/${action.payload.uid}`);

      usersRef.on('value', (snapshot) => {
        const data = snapshot.val();

        dispatch(userAdminReceived(data));
      });
    }
  };
