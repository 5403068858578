import axios from 'axios';
import firebase from '../../lib/firebaseHelpers';
import { REFRESH_IDENTITY } from '../../actions/index';
import getApiUrl from '../../lib/getApiUrl';
import {
  refreshIdentityFailure,
  refreshIdentitySuccess,
} from '../../actions/Identities';

const { auth } = firebase;

export default ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === REFRESH_IDENTITY) {
      const { id } = action.payload;

      const { identity: { data: identity } = {} } = getState();

      const token = await auth().currentUser.getIdToken();

      const isFlashIdentity =
        identity.type && identity.type.toLowerCase() === 'flash';

      const data = isFlashIdentity ? { identity } : {};

      const url = `${getApiUrl()}/${
        isFlashIdentity ? 'identity/cycle' : `identities/${id}/refresh`
      }`;

      try {
        await axios({
          method: 'post',
          url,
          headers: {
            token,
          },
          data,
        });

        dispatch(refreshIdentitySuccess(id));
      } catch (error) {
        console.log(error);
        dispatch(refreshIdentityFailure(id));
      }
    }
  };
