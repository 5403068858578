import * as React from 'react';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { RaisedButton } from 'material-ui';
import Row from './ui/Row';

export default class App extends React.Component {
  state = {
    value: '',
    tab: 'write',
  };

  converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  componentDidMount() {
    this.syncValue(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.syncValue(this.props);
    }
  }

  syncValue = (props) => {
    this.setState({ value: props.value });
  };

  handleValueChange = (value) => {
    this.setState({ value });
  };

  handleTabChange = (tab) => {
    this.setState({ tab });
  };

  saveChanges = () => {
    this.props.onChange(this.props.id, this.state.value);
  };

  render() {
    return (
      <div style={{ fontSize: 13 }}>
        <ReactMde
          onChange={this.handleValueChange}
          onTabChange={this.handleTabChange}
          value={this.state.value || ''}
          generateMarkdownPreview={(markdown) =>
            Promise.resolve(this.converter.makeHtml(markdown))
          }
          selectedTab={this.state.tab}
          onBlur={this.saveChanges}
          textAreaProps={{
            onBlur: this.saveChanges,
            style: { height: 236, margin: 0, width: '100%', fontSize: 13 },
          }}
        />
        <Row style={{ justifyContent: 'flex-end', marginTop: 5 }}>
          <RaisedButton primary label="Save" onClick={this.saveChanges} />
        </Row>
      </div>
    );
  }
}
