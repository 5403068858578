import { combineReducers } from 'redux';
import data from './data';
import inProgress from './inProgress';
import refresh from './refresh';

export default combineReducers({
  data,
  inProgress,
  refresh,
});
