import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LinkComponent from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { user as userAPI, billing as billingAPI } from 'mirage-api-client';
import { Switch } from '@material-ui/core';
import UserIdentities from './UserIdentities';
import ChildUsers from './ChildUsers';
import EditableTextField from '../../EditableTextField';
import FieldRow from '../../ui/FieldRow';
import Row from '../../ui/Row';
import FieldContainer from '../../ui/FieldContainer';
import Notes from './Notes';
import ConfirmationModel from '../../ui/ConfirmationButton';
import AdminPrivilege from './AdminPrivilege';
import UserDebug from './UserDebug';

import {
  LABLE_BUTTON,
  USER_FIELD,
  USER_STATUS,
  USER_STRINGS,
} from './constants';
import {
  loadUser,
  loadUsers,
  unmountUser,
  editUser,
} from '../../../actions/Users';

function UserDetails({
  user,
  users,
  loadUser: getUser,
  loadUsers: getUsers,
  unmountUser: unmount,
  match: { params: { uid } = {} } = {},
  ...props
}) {
  const {
    identityLimit,
    chargebeeCustomerId,
    chargebeeSubscriptionId,
    deleted,
    accountType,
    createdAt,
    suspendedAt,
    parentUID,
    status,
    trialExpiration,
    billingType,
    isBypassTrialAbuse,
  } = user || {};

  const suspendButtonDisabled = ['trial', 'deleted'].includes(status);

  const [rates, setRates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    getUser(uid);
    getUsers();
    return () => unmount(uid);
  }, [uid, getUser, getUsers, unmount]);

  useEffect(() => {
    billingAPI.getRates(uid).then(setRates);
  }, [uid]);

  const handleClose = () => {
    if (message) setMessage('');
  };

  const submitChange = (id, value) => props.editUser(uid, { [id]: value });

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const response = await userAPI.deleteUser(uid);
      if (response.status === 'success') {
        setMessage('User account cancelled');
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePurge = async () => {
    await userAPI.purgeUser(uid);
    props.history.replace('/users');
  };

  const onRateChange = (type, value) => {
    const newRates = rates || {
      Standard: null,
      Flash: null,
    };
    newRates[type] = Number(value);
    billingAPI.updateRates(uid, newRates).then(setRates);
  };

  const updateAccountType = (event) =>
    submitChange('accountType', event.target.value);

  const updateBillingType = (event) =>
    submitChange('billingType', event.target.value);

  const updateBypassTrialAbuse = (event) => {
    submitChange('isBypassTrialAbuse', event.target.checked);
  };

  function handleTrialPeriodChange(id, value) {
    const timestamp = new Date(value);
    const utcTimestamp =
      Date.UTC(
        timestamp.getFullYear(),
        timestamp.getMonth(),
        timestamp.getDate(),
        timestamp.getHours(),
        timestamp.getMinutes()
      ) / 1000;
    submitChange(id, utcTimestamp);
  }

  const handleSuspendUserChange = (value) => async (_e, callback) => {
    await submitChange(USER_STRINGS.STATUS, value);
    if (value === USER_STATUS.SUSPENDED) {
      const timestamp = new Date();
      const utcTimestamp =
        Date.UTC(
          timestamp.getFullYear(),
          timestamp.getMonth(),
          timestamp.getDate(),
          timestamp.getHours(),
          timestamp.getMinutes()
        ) / 1000;
      await submitChange(USER_STRINGS.SUSPENDED_AT, utcTimestamp);
    }
    callback(false);
  };

  const popupConfirm = useMemo(() => {
    switch (status) {
      case USER_STATUS.SUSPENDED:
        return {
          onClick: handleSuspendUserChange(USER_STATUS.ACTIVE),
          label: LABLE_BUTTON.UNSUSPEND,
          confirmationTerm: USER_STRINGS.UNSUSPEND,
        };
      default:
        return {
          onClick: handleSuspendUserChange(USER_STATUS.SUSPENDED),
          label: LABLE_BUTTON.SUSPEND,
          confirmationTerm: USER_STRINGS.SUSPEND,
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    document.title = 'User Detail | Mirage ID Admin';
  }, []);

  if (!(user && user.uid)) return null;

  return (
    <React.Fragment>
      <FieldContainer style={{ maxWidth: 600, paddingBottom: 0 }}>
        <h3>User</h3>
        {USER_FIELD.map(
          (field) =>
            (user[field.value] || field.isRequired) && (
              <FieldRow key={field.value} label={field.label}>
                {user[field.value] || '-'}
              </FieldRow>
            )
        )}
        {createdAt && (
          <FieldRow label="Created at">
            {new Intl.DateTimeFormat('en', {
              timeStyle: 'short',
              dateStyle: 'short',
            }).format(new Date(createdAt))}
          </FieldRow>
        )}
        <FieldRow label="Suspended at">
          {suspendedAt
            ? new Intl.DateTimeFormat('en', {
                timeStyle: 'short',
                timeZone: 'UTC',
                dateStyle: 'short',
              }).format(new Date(suspendedAt * 1000))
            : '-'}
        </FieldRow>
        {trialExpiration && (
          <FieldRow label="Trial Expiration">
            <EditableTextField
              id="trialExpiration"
              value={new Intl.DateTimeFormat('en', {
                timeStyle: 'short',
                timeZone: 'UTC',
                dateStyle: 'short',
              }).format(new Date(trialExpiration * 1000))}
              onChange={handleTrialPeriodChange}
            />
          </FieldRow>
        )}
        {deleted && (
          <FieldRow label="Deleted at">
            {new Intl.DateTimeFormat('en', {
              timeStyle: 'short',
              dateStyle: 'short',
            }).format(new Date(deleted))}
          </FieldRow>
        )}
        {Boolean(parentUID) && (
          <FieldRow label="ParentUser">
            <Link to={`/users/${parentUID}`}>
              {users[parentUID] ? users[parentUID].email : parentUID}
            </Link>
          </FieldRow>
        )}
        {!parentUID && (
          <>
            <FieldRow label="Identity Limit">
              <EditableTextField
                id="identityLimit"
                value={identityLimit}
                onChange={submitChange}
              />
            </FieldRow>

            <FieldRow label="Plan">
              <Select
                name="accountType"
                value={accountType}
                onChange={updateAccountType}
              >
                <MenuItem value="business">Business</MenuItem>
                <MenuItem value="home_office">Home Office</MenuItem>
                <MenuItem value="enhanced_vpn">SimpleVPN</MenuItem>
              </Select>
            </FieldRow>

            <FieldRow label="Billing Type">
              <Select
                name={user.id}
                value={billingType || 'standard'}
                onChange={updateBillingType}
              >
                <MenuItem value="standard">Standard</MenuItem>
                <MenuItem value="enterprise">Enterprise</MenuItem>
              </Select>
            </FieldRow>
          </>
        )}
        <FieldRow label="Debug Mode">
          <UserDebug />
        </FieldRow>
        {status === USER_STATUS.TRIAL && !parentUID && (
          <FieldRow label="Bypass Trial Abuse">
            <Switch
              name="isBypassTrialAbuse"
              value={!!isBypassTrialAbuse}
              checked={!!isBypassTrialAbuse}
              onChange={updateBypassTrialAbuse}
            />
          </FieldRow>
        )}
        <Row style={styles.deleteRow}>
          {!deleted ? (
            <ConfirmationModel
              isLoading={isLoading}
              message={message}
              onClose={handleClose}
              onClick={handleDelete}
              label="Delete User"
              confirmationTerm="cancel"
            />
          ) : (
            <ConfirmationModel
              isLoading={isLoading}
              message={message}
              onClose={handleClose}
              onClick={handlePurge}
              label="Purge User"
              confirmationTerm="purge"
            />
          )}
          <ConfirmationModel
            disabled={suspendButtonDisabled}
            onClick={popupConfirm.onClick}
            label={popupConfirm.label}
            confirmationTerm={popupConfirm.confirmationTerm}
          />
        </Row>
        {!parentUID && (
          <>
            <h3>Chargebee</h3>
            <FieldRow label="Customer Id">{chargebeeCustomerId}</FieldRow>
            <FieldRow label="Subscription Id">
              {chargebeeSubscriptionId}
            </FieldRow>
            <h3>Billing Rates</h3>
            <FieldRow label="Standard">
              <EditableTextField
                id="Standard"
                value={rates ? rates.Standard : null}
                onChange={onRateChange}
              />
            </FieldRow>
            <FieldRow label="Flash">
              <EditableTextField
                id="Flash"
                value={rates ? rates.Flash : null}
                onChange={onRateChange}
              />
            </FieldRow>
            <FieldRow label="VPN">
              <EditableTextField
                id="VPN"
                value={rates ? rates.VPN : null}
                onChange={onRateChange}
              />
            </FieldRow>
            <FieldRow label="Child User">
              <EditableTextField
                id="User"
                value={rates ? rates.User : null}
                onChange={onRateChange}
              />
            </FieldRow>
            <Link to={`/billing/${uid}`}>
              <LinkComponent
                underline="hover"
                variant="h6"
                style={{ marginTop: 15, display: 'block' }}
              >
                Billing
              </LinkComponent>
            </Link>
            <Notes uid={uid} />
          </>
        )}
      </FieldContainer>
      {!parentUID && (
        <>
          <ChildUsers uid={uid} />
          <UserIdentities uid={uid} />
          <AdminPrivilege />
        </>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.data,
  users: state.users.data,
});

const styles = {
  deleteRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
};

export default connect(mapStateToProps, {
  loadUser,
  loadUsers,
  unmountUser,
  editUser,
})(UserDetails);
