import React from 'react';
import Divider from 'material-ui/Divider';
import Row from './Row';
import Column from './Column';

const FieldRow = ({ label, children }) => (
  <Column>
    <Row style={styles.row}>
      <div style={styles.label}>{label}:</div>
      <div style={styles.value}>{children}</div>
    </Row>
    <Divider />
  </Column>
);

const styles = {
  row: {
    height: 38,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
  },
  label: { fontSize: 16, fontWeight: 600 },
  value: { fontSize: 16, fontWeight: 500 },
};

export default FieldRow;
