import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RaisedButton } from 'material-ui';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { identity as identityAPI } from 'mirage-api-client';
import {
  loadIdentity,
  unmountIdentity,
  refreshIdentity,
} from '../../../actions/Identities';
// import InstanceDays from './InstanceDays';
import EditableTextField from '../../EditableTextField';
import Row from '../../ui/Row';
import ConfirmationButton from '../../ui/ConfirmationButton';

import firebase from '../../../lib/firebaseHelpers';

class IdentityDetails extends React.Component {
  componentDidMount() {
    const id = this.getId();
    this.props.loadIdentity(id);
    document.title = 'Identity Detail | Mirage ID Admin';
  }

  removeIdentity = async (_e) => {
    const identity = this.props.identities[this.getId()];

    try {
      await firebase.db.ref().update({
        [`identities/${identity.id}/status`]: 'removed',
        [`identities/${identity.id}/deletedAt`]: new Date().toISOString(),
        [`users/${identity.userId}/identities/${identity.id}`]: null,
        [`users/${identity.userId}/removedIdentities/${identity.id}`]: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getId() {
    const { match: { params: { id } = {} } = {} } = this.props;
    return id;
  }

  cycle = () => {
    const id = this.getId();

    this.props.refreshIdentity(id);
  };

  updateIdentity = (key, value) => {
    return identityAPI.update(this.getId(), { [key]: value });
  };

  toggleHttps = (e) => {
    console.log(e.target.checked);
    this.updateIdentity('isHttps', e.target.checked);
  };

  render() {
    const {
      identities: {
        [this.getId()]: {
          containerRegion,
          createdAt,
          deletedAt = null,
          customerId,
          doIdentifier,
          enableAllowList,
          allowList,
          id,
          ip,
          name,
          request,
          status,
          userId,
          type,
          fonts,
          plugins,
          containerSize,
          autoSyncCookies,
          userName,
          userToken,
          port = '3128',
          isHttps = true,
        } = {},
      } = {},
      refresh: { [id]: { inProgress, error, success } = {} },
    } = this.props;

    if (!id) return null;

    return (
      <div style={styles.container}>
        <div>
          <Link to={`/users/${userId}`}>{`< User`}</Link>
        </div>
        <br />
        <div>{`Name: ${name}`}</div>
        <div>{`Type: ${type || 'Standard'}`}</div>
        <div>{`id: ${id}`}</div>
        <div>{`Region: ${containerRegion}`}</div>
        <div>{`Container id: ${customerId}-${doIdentifier}`}</div>
        <div>{`IP: ${ip}`}</div>
        <br />
        <div>{`Customer id: ${customerId}`}</div>
        <div>{`User id: ${userId}`}</div>
        <br />
        <div>{`Request: ${request}`}</div>
        <div>{`Status: ${status}`}</div>
        <div>{`Container size: ${containerSize}`}</div>
        <div>{`doIdentifier: ${doIdentifier}`}</div>
        <br />
        <div>{`Fonts: ${fonts}`}</div>
        <br />
        <div>{`Plugins: ${plugins}`}</div>
        <div>{`Username: ${userName}`}</div>
        <div>{`User token: ${userToken}`}</div>
        <br />
        <div>
          {`Created At: ${
            createdAt ? new Date(createdAt).toLocaleString() : 'invalid date'
          }`}
        </div>
        {deletedAt && (
          <div>{`Deleted At: ${new Date(deletedAt).toLocaleString()}`}</div>
        )}
        <div>{`Allow list enabled: ${enableAllowList}`}</div>
        {enableAllowList && <div>{`Allow List: ${allowList}`}</div>}
        <div>{`Auto sync cookies on: ${autoSyncCookies}`}</div>
        <Row style={{ maxWidth: 400, alignItems: 'center' }}>
          <label style={{ marginRight: 5 }}>Port:</label>
          <EditableTextField
            textAlign="left"
            id="port"
            value={port}
            onChange={this.updateIdentity}
          />
        </Row>
        <FormControlLabel
          control={<Switch checked={isHttps} onChange={this.toggleHttps} />}
          label="HTTPS"
        />
        {/* <div> */}
        {/* <h4>Billing</h4> */}
        {/* Instance Days:
          <InstanceDays identity={this.props.identities[this.getId()]} /> */}
        {/* </div> */}
        <br />
        {status !== 'removed' && (
          <div>
            <RaisedButton
              primary
              label="Cycle Container"
              onClick={this.cycle}
              disabled={inProgress}
            />
          </div>
        )}
        {inProgress && <div>...Refresh In Progress</div>}
        {success && <div>Refresh Success</div>}
        {error && <div>Error Refreshing Container</div>}
        {status !== 'removed' && status !== 'removing' && (
          <div style={{ marginTop: 15 }}>
            <ConfirmationButton
              confirmationTerm="delete"
              label="Delete Identity"
              onClick={this.removeIdentity}
              disabled={inProgress}
            />
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    const id = this.getId();

    this.props.unmountIdentity(id);
  }
}

const styles = {
  container: {
    padding: 40,
  },
};

const mapStateToProps = (state) => ({
  identities: state.identity.data,
  refresh: state.identity.refresh,
});

export default connect(mapStateToProps, {
  loadIdentity,
  unmountIdentity,
  refreshIdentity,
})(IdentityDetails);
