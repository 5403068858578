import React from 'react';
import { connect } from 'react-redux';
import { user as userAPI } from 'mirage-api-client';
import Switch from '@material-ui/core/Switch';

import Row from '../../ui/Row';

const DEBUG_USER_SEGMENT = 'debug-users';

function UserDebug({ user = {} }) {
  const { uid } = user;
  const [inProgress, setInProgress] = React.useState(false);
  const [segments, _error, loading] = userAPI.useSegments(
    uid,
    DEBUG_USER_SEGMENT
  );

  const isInDebugSegment =
    !loading &&
    Array.isArray(segments) &&
    segments.some((segment) => segment.key === DEBUG_USER_SEGMENT);

  async function handleDebugModeChange() {
    try {
      setInProgress(true);
      if (isInDebugSegment) {
        await userAPI.removeUserFromSegment(uid, DEBUG_USER_SEGMENT);
      } else {
        await userAPI.addUserToSegment(uid, DEBUG_USER_SEGMENT);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setInProgress(false);
    }
  }

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        marginTop: 4,
        flexDirection: 'row',
      }}
    >
      <Switch
        disabled={inProgress}
        value={isInDebugSegment}
        checked={isInDebugSegment}
        onChange={handleDebugModeChange}
      />
    </Row>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps)(UserDebug);
