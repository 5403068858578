import React from 'react';
import { connect } from 'react-redux';
import LoadingComponent from '../../../ui/LoadingComponent';
import {
  loadUserIdentities,
  unmountUserIdentities,
} from '../../../../actions/Identities/index';
import Table from '../../../ui/SearchableTable';
import FieldContainer from '../../../ui/FieldContainer';

const headersActive = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    modifier: (value) =>
      value ? new Date(value).toLocaleString() : 'invalid date',
  },
  {
    id: 'containerRegion',
    label: 'Region',
  },
  {
    id: 'autoSyncCookies',
    label: 'Cookie Sync',
    modifier: (value) => value?.toString() || 'false',
  },
];

const headersInactive = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    modifier: (value) =>
      value ? new Date(value).toLocaleString() : 'invalid date',
  },
  {
    id: 'deletedAt',
    label: 'Deleted At',
    modifier: (value) =>
      value ? new Date(value).toLocaleString() : 'invalid date',
  },
];

class UserIdentities extends React.Component {
  componentDidMount() {
    const { uid } = this.props;
    this.props.loadUserIdentities(uid);
  }

  render() {
    const { identities, inProgress } = this.props;
    const activeIdentities = !identities
      ? []
      : Object.values(identities).filter(({ status }) => status !== 'removed');
    const removedIdentities = !identities
      ? []
      : Object.values(identities).filter(({ status }) => status === 'removed');
    // eslint-disable-next-line no-nested-ternary
    return !inProgress ? (
      <React.Fragment>
        {/* <FieldContainer
            style={{ maxWidth: 600, paddingTop: 0, paddingBottom: 0 }}
          >
            <Billing identities={identities} />
          </FieldContainer> */}
        <FieldContainer style={{ paddingTop: 0 }}>
          <h3 style={styles.label}>User identities</h3>
          <h4>Active Identities</h4>
          <p> {activeIdentities.length} identities </p>
          <div style={styles.identities}>
            <Table
              data={
                identities
                  ? Object.entries(identities)
                      .filter(
                        ([_, { status }]) =>
                          status !== 'removing' && status !== 'removed'
                      )
                      .map(([id, data]) => ({
                        id,
                        ...data,
                      }))
                  : []
              }
              headers={headersActive}
              target={'/identities'}
            />
          </div>
          <h4>Inactive Identities</h4>
          <p> {removedIdentities.length} identities </p>
          <div style={styles.identities}>
            <Table
              data={
                identities
                  ? Object.entries(identities)
                      .filter(
                        ([_, { status }]) =>
                          status === 'removing' || status === 'removed'
                      )
                      .map(([id, data]) => ({
                        id,
                        ...data,
                      }))
                  : []
              }
              headers={headersInactive}
              target={'/identities'}
            />
          </div>
        </FieldContainer>
      </React.Fragment>
    ) : (
      <LoadingComponent />
    );
  }

  componentWillUnmount() {
    this.props.unmountUserIdentities(this.props.uid);
  }
}

const styles = {
  identities: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  label: {
    marginBottom: 0,
  },
};

const mapStateToProps = (state) => ({
  identities: state.userIdentities.data,
  inProgress: state.userIdentities.inProgress,
});

export default connect(mapStateToProps, {
  loadUserIdentities,
  unmountUserIdentities,
})(UserIdentities);
