import React from 'react';
import TextField from 'material-ui/TextField';
import { MdModeEdit, MdCheck } from 'react-icons/md';
import Row from './ui/Row';

class EditableTextField extends React.Component {
  static defaultProps = {
    textAlign: 'right',
    style: {},
  };

  textField = React.createRef();

  state = { editing: false, value: '' };

  onChange = (event) => this.setState({ value: event.target.value });

  startEditing = () => {
    this.setState(
      {
        editing: !this.state.editing,
        value: this.props.value,
      },
      () => {
        this.textField.current.focus();
      }
    );
  };

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onChange(this.props.id, this.state.value);
    this.toggleEditing();
  };

  render() {
    const { textAlign, ...otherProps } = this.props;

    return this.state.editing ? (
      <form onSubmit={this.onSubmit}>
        <Row style={{ alignItems: 'stretch' }}>
          <TextField
            {...otherProps}
            ref={this.textField}
            value={this.state.value}
            onChange={this.onChange}
            inputStyle={styles.input(textAlign)}
            fullWidth
          />
          <MdCheck onClick={this.onSubmit} style={styles.confirm} />
        </Row>
      </form>
    ) : (
      <Row style={{ alignItems: 'center', ...this.props.style }}>
        {this.props.value}
        <MdModeEdit onClick={this.startEditing} style={styles.edit} />
      </Row>
    );
  }
}

const styles = {
  edit: {
    height: 30,
    width: 20,
    marginLeft: 10,
    cursor: 'pointer',
  },
  confirm: {
    color: '#008800',
    height: 48,
    width: 30,
    marginLeft: 10,
    cursor: 'pointer',
  },
  input: (textAlign) => ({
    textAlign,
  }),
};

export default EditableTextField;
