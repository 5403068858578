import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider } from 'material-ui';
import Column from '../../ui/Column';
import FieldContainer from '../../ui/FieldContainer';

const useStyles = makeStyles({
  childRow: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

function ChildUsers({ users, uid }) {
  const childUsers = !users
    ? []
    : Object.values(users).filter(({ parentUID }) => parentUID === uid);
  return (
    <FieldContainer style={{ maxWidth: 600, paddingBottom: 0 }}>
      <Column>
        <h3>Child Users</h3>
        <p>{childUsers.length} users</p>
        {childUsers.map((user) => (
          <ChildUser key={user.uid} user={user} />
        ))}
      </Column>
    </FieldContainer>
  );
}

function ChildUser({ user }) {
  const classes = useStyles();
  return (
    <>
      <Divider />
      <Link
        style={{ margin: 10 }}
        to={`/users/${user.uid}`}
        className={classes.childRow}
      >
        <span>{user.email}</span>
        <span>{user.uid}</span>
      </Link>
    </>
  );
}

const mapStateToProps = (state) => ({
  users: state.users.data,
});

export default connect(mapStateToProps)(ChildUsers);
