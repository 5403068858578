import { LOGIN } from '../../actions';
import firebase from '../../lib/firebaseHelpers';
import { formError } from '../../actions/Form';

export default ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === LOGIN) {
      const {
        formFields: { email = '', password = '' },
      } = getState();

      try {
        const { user } = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        const { claims } = await user.getIdTokenResult();
        if (!claims.admin) {
          dispatch(
            formError(
              'The password is invalid or the user does not have a password.'
            )
          );
        }
      } catch (error) {
        dispatch(formError(error.message));
      }
    }
  };
