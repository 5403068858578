import React from 'react';
import {
  makeStyles,
  Backdrop,
  CircularProgress,
  Container,
} from '@material-ui/core';
import BillingList from 'mirage-components/dist/billing/List';
import { billing } from 'mirage-api-client';
import { withRouter, useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { loadUsers, unmountUsers } from '../../actions/Users';
import Config from '../../config';

const useStyles = makeStyles({
  buttonRow: {
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  backdrop: {
    flex: 1,
    zIndex: 1,
  },
  noIdentities: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: 80,
  },
  fab: {
    position: 'fixed',
    right: 16,
    bottom: 16,
    zIndex: 100,
  },
  downloadCSV: {
    marginTop: 8,
  },
});

function Billing({ history, loadUsers: load, users: { data: users } }) {
  const classes = useStyles();
  const [bills, setBills] = React.useState(false);
  const [periods, setPeriods] = React.useState(false);
  const location = useLocation();
  const period = React.useMemo(() => {
    if (!location.search) return false;
    return new URLSearchParams(location.search).get('period');
  }, [location]);

  React.useEffect(() => {
    load();
  }, [load]);

  React.useEffect(() => {
    billing.periods().then(setPeriods);
  }, []);

  React.useEffect(() => {
    if (period) {
      billing.list(period).then(setBills);
    } else {
      billing.list().then(setBills);
    }
  }, [period]);

  const onChangePeriod = (periodId) => {
    history.push(`/billing/?period=${periodId}`);
  };

  const onSelectUser = (row) => {
    window.open(
      `${window.location.origin}/billing/${row.user.uid}?period=${row.billing_period.id}`,
      '_blank'
    );
  };

  const csvData = React.useMemo(
    () =>
      bills && bills.data
        ? bills.data
            .filter((item) => item.identity_days?.total > 0)
            .map((bill) => {
              const thisUser = users[bill.user.uid] || {};
              return {
                Email: bill.user?.email,
                Status: bill.user?.status,
                'Account Type': thisUser.accountType,
                Identities: bill.active_identities,
                'Identity Days': bill.identity_days.Standard,
                'Flash Identity Days': bill.identity_days.Flash,
                'Total Identity Days': bill.identity_days.total,
                'Invoice Id':
                  bill?.billingData?.billingInfo?.['invoice_id'] || '',
                Notes: bill.user.notes,
                'Adjusted Total': bill.adjustedTotal,
                'Billing Type': !thisUser.parentUID
                  ? thisUser.billingType || 'standard'
                  : '',
              };
            })
            .sort((a, b) => {
              const billA = a?.['Invoice Id'] || '';
              const billB = b?.['Invoice Id'] || '';
              return billB.localeCompare(billA);
            })
        : [['email']],
    [bills, users]
  );

  React.useEffect(() => {
    document.title = 'Billing List | Mirage ID Admin';
  }, []);

  if (bills && bills.data) {
    bills.data.forEach((bill) => {
      const thisUser = users[bill.user.uid] || {};

      // eslint-disable-next-line no-param-reassign
      bill.billingType = !thisUser.parentUID
        ? thisUser.billingType || 'standard'
        : '';
    });
  }

  if (bills && periods) {
    return (
      <Container>
        <div className={classes.downloadCSV}>
          <CSVLink
            data={csvData}
            filename={`mirage-billing-${bills.period.id}.csv`}
            target="_blank"
          >
            Download User List For Period
          </CSVLink>
        </div>
        <BillingList
          invoiceUrl={Config.chargebeeUrl}
          bills={bills}
          periods={periods}
          onChangePeriod={onChangePeriod}
          onSelectUser={onSelectUser}
        />
      </Container>
    );
  }

  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color="primary" />
    </Backdrop>
  );
}

const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, {
  loadUsers,
  unmountUsers,
})(withRouter(Billing));
