import {
  REFRESH_IDENTITY,
  REFRESH_IDENTITY_FAILURE,
  UNMOUNT_IDENTITY,
  REFRESH_IDENTITY_SUCCESS,
} from '../../../actions';

const initialState = {};

const initialStateShape = {
  inProgress: false,
  error: false,
  success: false,
};

export default function refreshReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_IDENTITY:
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          ...initialStateShape,
          inProgress: true,
        },
      };
    case REFRESH_IDENTITY_FAILURE:
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          ...initialStateShape,
          inProgress: false,
          error: true,
        },
      };
    case REFRESH_IDENTITY_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...(state[action.payload.id] || {}),
          ...initialStateShape,
          inProgress: false,
          success: true,
        },
      };
    case UNMOUNT_IDENTITY: {
      const { [action.payload.id]: _, ...rest } = state;
      return rest;
    }
    default:
      return state;
  }
}
