const USER_FIELD = [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'phone',
    label: 'Phone Number',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'address',
    label: 'Address',
  },
  {
    value: 'city',
    label: 'City',
  },
  {
    value: 'state',
    label: 'state',
  },
  {
    value: 'zipcode',
    label: 'Zipcode',
  },
  {
    value: 'company',
    label: 'Company',
  },
  {
    value: 'uid',
    label: 'UID',
    isRequired: true,
  },
  {
    value: 'status',
    label: 'Status',
    isRequired: true,
  },
];

const USER_STATUS = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  TRIAL: 'trial',
};

const LABLE_BUTTON = {
  SUSPEND: 'Suspend User',
  UNSUSPEND: 'Unsuspend User',
};

const USER_STRINGS = {
  SUSPENDED_AT: 'suspendedAt',
  STATUS: 'status',
  SUSPEND: 'suspend',
  UNSUSPEND: 'unsuspend',
};

export { LABLE_BUTTON, USER_FIELD, USER_STATUS, USER_STRINGS };
