import users from './users';
import user from './user';
import userAdmin from './userAdmin';
import userIdentities from './userIdentities';
import identities from './identities';
import auth from './auth';

export default [
  ...users,
  ...user,
  ...userAdmin,
  ...userIdentities,
  ...identities,
  ...auth,
];
