import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextFieldPrimitive from 'material-ui/TextField';
import { updateFormField } from '../actions/Form/index';

class TextField extends Component {
  onChange = (event, value) => {
    this.props.updateFormField(this.props.id, value);
  };

  render() {
    const { fields, label, id, updateFormField: _, ...props } = this.props;

    const value = fields[id] || '';

    return (
      <TextFieldPrimitive
        value={value}
        onChange={this.onChange}
        floatingLabelText={label}
        fullWidth
        {...props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  fields: state.formFields,
});

export default connect(mapStateToProps, {
  updateFormField,
})(TextField);
