// @flow

import React from 'react';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import { updateFormField } from '../actions/Form/index';

class DebouncedTextField extends React.Component {
  state = { value: '', timeout: null };

  onChange = () => {
    this.props.updateFormField(this.props.id, this.state.value);
  };

  updateValue = (e, value) => {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }

    const timeout = setTimeout(() => this.onChange(), 100);
    this.setState({ value, timeout });
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { fields, label, id, updateFormField: _, ...props } = this.props;

    return (
      <TextField
        value={this.state.value}
        onChange={this.updateValue}
        floatingLabelText={label}
        fullWidth
        {...props}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  fields: state.formFields,
});

export default connect(mapStateToProps, {
  updateFormField,
})(DebouncedTextField);
