import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';

const AppRouter = () => (
  <Switch>
    {routes.map(({ path, exact = false, component }, index) => (
      <Route key={index} path={path} exact={exact} component={component} />
    ))}
  </Switch>
);

export default AppRouter;
