import React from 'react';

const Row = ({ children, style }) => (
  <div style={{ ...styles.row, ...style }}>{children}</div>
);

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default Row;
