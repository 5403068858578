import { LOAD_USER, USER_RECEIVED, UNMOUNT_USER } from '../../actions';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER:
      return initialState;
    case USER_RECEIVED:
      return action.payload.data;
    case UNMOUNT_USER:
      return initialState;
    default:
      return state;
  }
};
