import firebase from '../../lib/firebaseHelpers';
import { LOAD_IDENTITY } from '../../actions/index';
import { identityReceived } from '../../actions/Identities/index';

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type === LOAD_IDENTITY) {
      const { db } = firebase;
      const { id } = action.payload;

      const identitiesRef = db.ref(`identities/${id}`);

      identitiesRef.on('value', (snapshot) => {
        const data = snapshot.val();

        dispatch(identityReceived(data));
      });
    }
  };
