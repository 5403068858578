import {
  LOAD_USER_IDENTITIES,
  USER_IDENTITIES_RECEIVED,
  UNMOUNT_USER_IDENTITIES,
  LOAD_IDENTITY,
  IDENTITY_RECEIVED,
  UNMOUNT_IDENTITY,
  REFRESH_IDENTITY,
  REFRESH_IDENTITY_SUCCESS,
  REFRESH_IDENTITY_FAILURE,
} from '..';

export const loadUserIdentities = (uid) => ({
  type: LOAD_USER_IDENTITIES,
  payload: {
    uid,
  },
});

export const userIdentitiesReceived = (data) => ({
  type: USER_IDENTITIES_RECEIVED,
  payload: {
    data,
  },
});

export const unmountUserIdentities = () => ({
  type: UNMOUNT_USER_IDENTITIES,
});

export const loadIdentity = (id) => ({
  type: LOAD_IDENTITY,
  payload: {
    id,
  },
});

export const identityReceived = (data) => ({
  type: IDENTITY_RECEIVED,
  payload: {
    data,
  },
});

export const unmountIdentity = (id) => ({
  type: UNMOUNT_IDENTITY,
  payload: {
    id,
  },
});

export const refreshIdentity = (id) => ({
  type: REFRESH_IDENTITY,
  payload: {
    id,
  },
});

export const refreshIdentitySuccess = (id) => ({
  type: REFRESH_IDENTITY_SUCCESS,
  payload: {
    id,
  },
});

export const refreshIdentityFailure = (id) => ({
  type: REFRESH_IDENTITY_FAILURE,
  payload: {
    id,
  },
});
