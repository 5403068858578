import {
  LOAD_USERS,
  USERS_RECEIVED,
  UNMOUNT_USERS,
  LOAD_USER,
  USER_RECEIVED,
  UNMOUNT_USER,
  EDIT_USER,
} from '..';

export const loadUsers = () => ({
  type: LOAD_USERS,
});

export const usersReceived = (data) => ({
  type: USERS_RECEIVED,
  payload: {
    data,
  },
});

export const unmountUsers = () => ({
  type: UNMOUNT_USERS,
});

export const loadUser = (uid) => ({
  type: LOAD_USER,
  payload: {
    uid,
  },
});

export const userReceived = (data) => ({
  type: USER_RECEIVED,
  payload: {
    data,
  },
});

export const unmountUser = (uid) => ({
  type: UNMOUNT_USER,
  payload: {
    uid,
  },
});

export const editUser = (uid, fields) => ({
  type: EDIT_USER,
  payload: {
    uid,
    fields,
  },
});
